<template>
  <TransitionRoot as="template" :show="showModal">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-min sm:w-full min-h-full max-h-screen">
            <header class="bg-white px-6 pt-6 flex flex-wrap items-center">
              <h1 class="text-2xl font-semibold text-gray-800">Manage users of {{ name }}</h1>
              <h2 class="mt-6 text-sm font-normal text-gray-600"><strong>Important:</strong> Users with access to this organization will also have access to all of its buildings and spaces.</h2>
            </header>
            <main class="relative">
              <div v-if="isLoadingUsers" class="absolute grid place-content-center z-50 w-full h-full bg-white bg-opacity-10 backdrop-filter backdrop-blur-sm">
                <svg class="animate-spin" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13.25" cy="12.75" r="10.75" stroke="#F9CFE1" stroke-width="4"/>
                <path d="M24 12.75C24 6.81294 19.1871 2 13.25 2C7.31294 2 2.5 6.81294 2.5 12.75C2.5 18.6871 7.31294 23.5 13.25 23.5" stroke="#F42272" stroke-width="4" stroke-linecap="round"/>
                </svg>
              </div>
              <div class="mt-8 px-6 py-2 ">
                <div class="mb-4 p-4 px-5 flex flex-col md:flex-row bg-gray-50 rounded-md text-gray-800">
                  <div class="flex-col w-80">
                    <label for="select-name" class="block text-sm font-sm text-gray-800">User</label>
                    <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="select-name"
                          class="focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Search by name or email"
                          aria-describedby=""
                          tabindex="-1"
                          autocomplete="off"
                          v-model="userQuery"
                          @focus="showUserDropdown = true"
                          @blur="showUserDropdown = false"
                        >
                        <div class="absolute w-full mt-1 bg-white overflow-y-auto rounded-md shadow-md max-h-40" v-show="showUserDropdown">
                          <ul class="suggestions">
                            <li v-for="user in filteredUsers" :key="user.id" @mousedown.stop="select(user)">
                              <div class='p-2 hover:bg-gray-200 cursor-pointer whitespace-nowrap text-sm text-gray-900'>
                                {{ user.name }}
                                <span class='text-xs text-gray-600 block'>{{ user.email }}</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="ml-8 flex-col w-80">
                    <label for="permission" class="block text-sm font-sm text-gray-800">Permission</label>
                    <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
                      <div class="mt-1 relative rounded-md shadow-sm">
                        <select
                          autocomplete=""
                          tabindex="-1"
                          class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          name="permission"
                          v-model="permission"
                        >
                          <option disabled selected value=undefined>Please select</option>
                          <option value="read">Read</option>
                          <option value="write">Write</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="ml-8 flex flex-col w-36 justify-end">
                    <div>
                      <button v-if="isAddingUser" class="inline-flex items-center w-full px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                        <svg class="-ml-1 mr-2 h-3 w-3 animate-spin" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle opacity="0.5" cx="10" cy="10" r="8" stroke="currentColor" stroke-width="3"/>
                          <path d="M10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
                        </svg>
                        Adding user...
                      </button>
                      <button
                        v-else
                        class="disabled:opacity-50 w-full px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                        @click="addUser()"
                        :disabled="addUserButtonDisabled"
                      >
                      Add user
                      </button>
                    </div>
                  </div>
                </div>

                <h3 class="mt-4 text-gray-800 text-base font-semibold">Users</h3>

                <div class="mt-4 h-80 overflow-y-auto">
                  <div class="pb-2 align-middle inline-block min-w-full overflow-hidden rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead class="bg-gray-50">
                        <tr>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            User
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Permissions
                          </th>
                          <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Access
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="user in users" :key="user.email" class="hover:bg-gray-100">
                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {{ user.name }}
                            <span class="text-xs text-gray-600 block">{{ user.email }}</span>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900 capitalize">
                            {{ user.permission }}
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {{ user.permissionType }}
                          </td>
                          <td>
                            <Menu as="div" class="mr-2 relative" :hidden="hideMenuForUser(user)">
                              <div>
                                <div v-if="isRemovingUser === user.oid" class="grid place-content-center z-50 w-full h-full">
                                  <svg class="animate-spin" width="16" height="16" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="13.25" cy="12.75" r="10.75" stroke="#F9CFE1" stroke-width="4"/>
                                  <path d="M24 12.75C24 6.81294 19.1871 2 13.25 2C7.31294 2 2.5 6.81294 2.5 12.75C2.5 18.6871 7.31294 23.5 13.25 23.5" stroke="#F42272" stroke-width="4" stroke-linecap="round"/>
                                  </svg>
                                </div>
                                <MenuButton v-else class="bg-gray-100 ml-auto p-0.5 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500 print:hidden" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                  <span class="sr-only">Open options</span>
                                  <DotsVerticalIcon class="h-4 w-4" />
                                </MenuButton>
                              </div>
                              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                  <div class="py-1" role="none">
                                    <MenuItem as="button" @click="removeUserPermission(user)" class='disabled:opacity-50 text-red-700 block px-4 py-2 text-sm text-left hover:bg-gray-100 cursor-pointer disabled:cursor-not-allowed w-full' id="menu-item-1">Delete permission</MenuItem>
                                  </div>
                                </MenuItems>
                              </transition>
                            </Menu>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <TransitionChild
                as="div"
                :show="errorMessage != null"
                enter="transition-opacity ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0">
                <toast
                  v-if="errorMessage != null"
                  class="fixed bottom-16 left-0 right-0 m-4 shadow z-50"
                  :message="errorMessage ?? ''"
                  @close="closeErrorToast()"
                />
              </TransitionChild>
            </main>
            <footer class="bg-white bottom-0 border-t px-4 py-3 flex flex-wrap items-baseline justify-end">
              <button
                class="inline-flex items-center px-4 py-2 border border-transparent sm:text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                @click="$emit('close')"
              >
              Close
              </button>
            </footer>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { DotsVerticalIcon } from '@heroicons/vue/solid';
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';
import { computed, onUpdated, ref } from '@vue/runtime-core';
import { isNullUndefinedOrEmpty } from '@/models/create-building';
import { useStore } from 'vuex';
import { Permission } from '@/models/user-permission';
import Toast from '@/components/modals/Toast.vue';

export default {
  name: 'UserManagement',
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsVerticalIcon,
    Toast
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    buildingId: {
      type: String,
      required: false,
      default: undefined
    },
    spaceId: {
      type: String,
      required: false,
      default: undefined
    }
  },
  setup(props) {
    const store = useStore();
    const userQuery = ref(null);
    const permission = ref(undefined);
    const addUserButtonDisabled = computed(() => userIsValidAndPermissionSelected());
    const showUserDropdown = ref(false);
    const selectedUser = ref(null);
    const filteredUsers = computed(() => filterUsersByQuery(userQuery.value));
    const users = computed(() => store.getters.users.filter(user => user.permission != null));
    const errorMessage = ref(null);

    const isLoadingUsers = ref(false);
    const isAddingUser = ref(false);
    const isRemovingUser = ref(null);

    async function loadUsers() {
      isLoadingUsers.value = true;
      try {
        await store.dispatch('getAllUsers', { building_id: props.buildingId, space_id: props.spaceId });
      } catch (error) {
        errorMessage.value = 'Unable to load users.';
      } finally {
        isLoadingUsers.value = false;
      }
    }

    function userIsValidAndPermissionSelected() {
      return selectedUser.value == null || isNullUndefinedOrEmpty(userQuery.value) || isNullUndefinedOrEmpty(permission.value);
    }

    function select(user) {
      selectedUser.value = user;
      userQuery.value = user.name;
      showUserDropdown.value = false;
    }

    function filterUsersByQuery(query) {
      return store.getters.users.filter((user, index, allUsers) => {
        const subsetUser = { name: user.name, email: user.email };
        const isUniqueUser = allUsers.findIndex(currentUser => (currentUser.name === user.name && currentUser.email === user.email)) === index;
        return isUniqueUser && Object
          .values(subsetUser)
          .map(value => String(value).toLowerCase())
          .some(value => value.includes(query?.toLowerCase() ?? ''));
      });
    }

    async function addUser() {
      const user = {
        oid: selectedUser.value.oid,
        policy: permission.value,
        buildingId: props.buildingId ?? '*',
        spaceId: props.spaceId ?? '*'
      };

      isAddingUser.value = true;
      try {
        await store.dispatch('addUserPermission', user);
        isAddingUser.value = false;
        await loadUsers();
        selectedUser.value = null;
        userQuery.value = null;
        permission.value = undefined;
        showUserDropdown.value = false;
      } catch (error) {
        errorMessage.value = `Unable to add ${permission.value} permission for ${selectedUser.value.name}.`;
        isAddingUser.value = false;
      }
    }

    async function removeUserPermission(user) {
      const request = {
        oid: user.oid,
        policy: user.permission,
        buildingId: props.buildingId ?? '*',
        spaceId: props.spaceId ?? '*'
      };

      isRemovingUser.value = user.oid;
      try {
        await store.dispatch('removeUserPermission', request);
        await loadUsers();
        isRemovingUser.value = null;
      } catch (error) {
        errorMessage.value = `Unable to remove ${user.permission} permission for ${user.name}.`;
        isRemovingUser.value = null;
      }
    }

    function hideMenuForUser(user) {
      return user.permission === Permission.policy_admin;
    }

    function closeErrorToast() {
      errorMessage.value = null;
    }

    onUpdated(async () => {
      if (props.showModal) {
        loadUsers();
        selectedUser.value = null;
        userQuery.value = null;
      }
    });

    return {
      userQuery,
      permission,
      addUserButtonDisabled,
      addUser,
      users,
      filteredUsers,
      showUserDropdown,
      select,
      isLoadingUsers,
      isAddingUser,
      isRemovingUser,
      removeUserPermission,
      hideMenuForUser,
      errorMessage,
      closeErrorToast
    };
  }
};
</script>
