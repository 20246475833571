<template>
  <div class="flex flex-col justify-center items-center h-screen">
    <h1 class="font-bold text-lg text-gray-800">The page you are looking for isn't available.</h1>
    <p class="text-md text-gray-400">The link you followed may be broken or the page may not exist.</p>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>