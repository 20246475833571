<template>
  <div id="content-container">
    <!-- Banner for Beta Preview -->
    <dashboard-preview-banner v-if="!isProduction" title="Beta Preview" />
    <div class="h-screen flex overflow-hidden print:overflow-visible bg-white">
      <!-- Menu sidebar for mobile -->
      <TransitionRoot as="template" :show="sidebarOpen">
        <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
            leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </TransitionChild>
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full" enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
            leave-to="-translate-x-full">
            <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button type="button"
                    class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex-shrink-0 flex items-center px-4">
                <router-link :to="{ name: 'AppContainer' }"><img class="h-8 w-auto" :src="getImgUrl()" alt="Workflow" />
                </router-link>
              </div>
              <div class="mt-5 flex-1 h-0 overflow-y-auto">
                <nav class="px-2 space-y-1">
                  <router-link v-for="item in navigation" :key="item.name" :to="{ name: item.name }"
                    @click="updateCurrentTab(item)"
                    :class="[item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']">
                    <component :is="item.icon"
                      :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']"
                      aria-hidden="true" />
                    {{ item.title }}
                  </router-link>
                </nav>
              </div>
              <p class="text-xs text-gray-300 text-center p-3">
                Version {{ version }}
                <a target="_blank" class="block text-pink-600 underline hover:no-underline"
                  href="https://help.particleone.com/reference-guides/user-guide/">User guide</a>
              </p>
            </div>
          </TransitionChild>
          <div class="flex-shrink-0 w-14" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Static sidebar for desktop -->
      <div class="hidden md:flex md:flex-shrink-0 print:hidden">
        <div class="flex flex-col w-64">
          <!-- Sidebar component, swap this element with another sidebar if you like -->
          <div class="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto">
            <div class="flex items-center flex-shrink-0 px-4">
              <router-link :to="{ name: 'AppContainer' }"><img class="h-8 w-auto" :src="getImgUrl()" alt="Workflow" />
              </router-link>
            </div>
            <div class="mt-5 flex-grow flex flex-col">
              <nav class="flex-1 px-2 bg-white space-y-1">
                <router-link v-for="item in navigation" :key="item.name" :to="{ name: item.name }"
                  @click="updateCurrentTab(item)"
                  :class="[item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md']">
                  <component :is="item.icon"
                    :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']"
                    aria-hidden="true" />
                  {{ item.title }}
                </router-link>
              </nav>
            </div>
            <p class="text-xs text-gray-300 text-center px-3 py-5">
              Version {{ version }}
              <a target="_blank" class="block text-pink-600 underline hover:no-underline"
                href="https://help.particleone.com/reference-guides/user-guide/">User guide</a>
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-0 flex-1 overflow-hidden print:overflow-visible">
        <!-- Header -->
        <div class="relative z-10 flex-shrink-0 flex h-16 bg-white shadow border-b">
          <button type="button"
            class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden print:hidden"
            @click="sidebarOpen = true">
            <span class="sr-only">Open sidebar</span>
            <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
          </button>
          <div class="flex-1 px-4 flex justify-between">
            <div class="flex-1 flex items-center">
              <loading-spinner v-if="isLoadingTenants" class="h-4 w-4" />
              <h1 v-else class="flex items-center font-bold text-lg text-gray-800">
                {{ tenant?.name }}
              </h1>
              <Menu as="div" class="mx-2 relative">
                <MenuButton
                  class="bg-gray-100 ml-auto p-0.5 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
                  id="menu-button" aria-expanded="true" aria-haspopup="true">
                  <span class="sr-only">Open options</span>
                  <DotsVerticalIcon class="h-4 w-4" />
                </MenuButton>
                <transition enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95">
                  <MenuItems
                    class="origin-top-left absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                    role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div class="py-1" role="none">
                      <MenuItem as="button" @click="userManagementModalOpen = true"
                        class='text-gray-700 block px-4 py-2 text-sm text-left hover:bg-gray-100 cursor-pointer w-full'
                        id="menu-item-0">Manage users</MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
            <div class="ml-4 flex items-center md:ml-6 hidden print:flex">
              <img class="h-8 w-auto" :src="getImgUrl()" alt="Workflow" />
            </div>
            <div class="ml-4 flex items-center md:ml-6 print:hidden">
              <button type="button"
                class="hidden bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <span class="sr-only">View notifications</span>
                <BellIcon class="h-6 w-6" aria-hidden="true" />
              </button>

              <!-- Profile dropdown -->
              <Menu as="div" class="ml-3 relative">
                <div>
                  <MenuButton
                    class="max-w-xs bg-white flex items-center text-sm text-gray-400 hover:text-gray-500 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 print:hidden">
                    <span class="sr-only">Open user menu</span>
                    <UserCircleIcon class="h-8 w-8" />
                  </MenuButton>
                </div>
                <transition enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95">
                  <MenuItems
                    class="origin-top-right absolute right-0 mt-2 min-w-48 max-w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    <div class="px-4 py-3">
                      <p class="text-sm">
                        Signed in as
                      </p>
                      <p class="text-sm font-medium text-gray-900 truncate">
                        {{ userEmail }}
                      </p>
                    </div>
                    <MenuItem v-if="hasMultipleTenants" as="button" @click="showTenantSelector()"
                      :class="['px-4 py-3 text-sm text-gray-700 text-left w-full']">
                    Switch Tenant
                    </MenuItem>
                    <MenuItem as="button" @click="handleLogout()"
                      :class="['px-4 py-3 text-sm text-gray-700 text-left w-full']">
                    Logout
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>

        <main class="flex-1 relative overflow-y-auto focus:outline-none print:overflow-visible">
          <loading-overlay v-if="isLoading" />
          <router-view v-if="hasValidTenant"></router-view>
          <page-not-found v-else />

          <user-management :showModal="userManagementModalOpen" :name="tenant?.name ?? ''"
            @close="userManagementModalOpen = false" />
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  BellIcon,
  CalendarIcon,
  FolderIcon,
  HomeIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
  UserCircleIcon
} from '@heroicons/vue/outline';
import { SearchIcon, DotsVerticalIcon } from '@heroicons/vue/solid';
import { mapActions, useStore } from 'vuex';
import UserManagement from '@/components/user-management/UserManagement.vue';
import DashboardPreviewBanner from '@/components/dashboard/DashboardPreviewBanner.vue';
import LoadingOverlay from './overlays/LoadingOverlay.vue';
import LoadingSpinner from './spinners/LoadingSpinner.vue';
import PageNotFound from './error/PageNotFound.vue';
import { useRouter } from 'vue-router';

const navigation = [
  { name: 'DashboardBuildingSummary', title: 'Dashboard', href: '/dashboard/', icon: HomeIcon, current: true },
  { name: 'Users', title: 'Users', href: '/users/', icon: UsersIcon, current: false },
  { name: 'Settings', title: 'Settings', href: '/settings/', icon: FolderIcon, current: false },
  { name: 'Help', title: 'Help', href: '/help/', icon: CalendarIcon, current: false },
].filter(option => option.name === 'DashboardBuildingSummary');

export default {
  name: 'ContentContainer',
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuAlt2Icon,
    SearchIcon,
    XIcon,
    UserCircleIcon,
    DotsVerticalIcon,
    UserManagement,
    LoadingOverlay,
    LoadingSpinner,
    PageNotFound,
    DashboardPreviewBanner,
  },
  setup() {
    const sidebarOpen = ref(false);
    const isProduction = ref(process.env.VUE_APP_ENVIRONMENT === 'production');
    const userManagementModalOpen = ref(false);
    const store = useStore();
    const router = useRouter();
    const userEmail = computed(() => store.getters.userEmail);
    const isLoading = computed(() => store.getters.isLoading);
    const version = computed(() => process.env.VUE_APP_VERSION_STRING);
    const tenant = computed(() => store.getters.tenants.find(tenant => tenant.slug === router.currentRoute.value.params.tenantId));
    const isLoadingTenants = ref(false);
    const hasMultipleTenants = computed(() => store.getters.tenants.length > 1);
    const hasValidTenant = ref(true);

    async function getAllTenants() {
      isLoadingTenants.value = true;
      await store.dispatch('getAllTenants');
      hasValidTenant.value = tenant.value != null;
      isLoadingTenants.value = false;
    }

    onMounted(() => {
      getAllTenants();
    });

    return {
      isProduction,
      navigation,
      sidebarOpen,
      userEmail,
      isLoading,
      version,
      tenant,
      userManagementModalOpen,
      isLoadingTenants,
      hasMultipleTenants,
      hasValidTenant
    };
  },
  methods: {
    updateCurrentTab(tab) {
      navigation.forEach(item => item.current = false);
      tab.current = true;
    },
    async handleLogout() {
      await this.logout();
      this.$router.push('/login/');
    },
    showTenantSelector() {
      if (this.hasMultipleTenants) {
        this.$router.push('/tenants/');
      }
    },
    getImgUrl() {
      const img = this.isProduction ? 'logo-dark' : 'logo-grayscale-dark';
      // eslint-disable-next-line global-require
      return require(`@/assets/svg/${img}.svg`);
    },
    ...mapActions(['logout']),
  }
};
</script>
